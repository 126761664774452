import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import Form from '@/components/common/Form'

const EntryForm = ({ theme, form }) => (
  <Root>
    <Main theme={theme}>
      <Form {...form} scale={800} />
    </Main>
  </Root>
)

const Root = styled.div``

const Main = styled.div`
`

export default ThemeConsumer(EntryForm)
