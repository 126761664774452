import React, { Fragment } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-recruit.png'
import MainMessage from '@/components/common/MainMessage'
import Form from '@/components/modules/Recruit/Entry/Form'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import LinkButton1 from '@/components/common/LinkButton1'

const RecruitEntryIndexPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const form = data.form.edges[0].node
      return (
        <Root>
          <SEO location={props.location} title="応募フォーム | 採用情報" description=""/>
          <Head>
            <PageTitle src={imgTitle}>応募フォーム</PageTitle>
            <MainMessage
              body={(
                <Fragment>フロントビジョンへの入社をご希望の方は、下記フォームへご入力ください。</Fragment>
              )}
              css={styles.MainMessage}
            />
          </Head>
          <Main>
            <Row css={styles.Form}><Form form={form} /></Row>
            <Row css={styles.Link}><Button to="/recruit/" component={Link} scale={800}>採用トップページ</Button></Row>
            <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
            <Row css={styles.Contact}><ContactLead /></Row>
          </Main>
        </Root>
      )
    }}
  />
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const Button = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const styles = {
  MainMessage: css`
  @media ${mq.and(mobile)} {
    br {
      display: initial;
    }
  }
`,
  Form: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Link: css`
    text-align: center;
  `
}

export default PageBase(RecruitEntryIndexPage)

export const query = graphql`
  query RecruitEntryIndexPageQuery {
    form: allRecruitEntryFormYaml {
      edges {
        node {
          inputFields {
            name
            label
            required
            type
            values {
              label
              value
            }
          }
          action
        }
      }
    }
  }
`
